module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Vessel","short_name":"Vessel","start_url":"/","themeColor":"#6C63FF","background_color":"#fefefe","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8db2d573671eeb5c96417f3f520091cf"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
